var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"tableCbt"}},[(_vm.status.status_spp === 'unpaid')?_c('a-alert',{staticClass:"mb-4",attrs:{"message":"Warning","description":"If you want to take the exam, make sure you have completed school administration.","type":"warning","show-icon":""}}):_vm._e(),_c('div',{staticClass:"d-lg-flex mb-5 mb-lg-2"},[_c('a-form-item',{staticClass:"mr-lg-3"},[_c('a-input',{staticStyle:{"height":"40px"},attrs:{"placeholder":"Search by Subject..."},on:{"keyup":_vm.searchCBTList},model:{value:(_vm.searchCBT),callback:function ($$v) {_vm.searchCBT=$$v},expression:"searchCBT"}})],1),_c('a-form-item',{staticClass:"mr-lg-3"},[_c('a-select',{class:!_vm.isDesktop ? 'w-100' : null,staticStyle:{"min-width":"100px"},attrs:{"size":"large","default-value":"All"},on:{"change":_vm.handleSelectType}},[_c('a-select-option',{attrs:{"value":""}},[_vm._v("All Type")]),_c('a-select-option',{attrs:{"value":"PH"}},[_vm._v("PH")]),_c('a-select-option',{attrs:{"value":"PTS"}},[_vm._v("PTS")]),_c('a-select-option',{attrs:{"value":"PAS"}},[_vm._v("PAS")]),_c('a-select-option',{attrs:{"value":"Others"}},[_vm._v("Others")])],1)],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%","height":"40px"},attrs:{"type":"default"},on:{"click":function($event){$event.preventDefault();return _vm.refreshTable.apply(null, arguments)}}},[_c('a-icon',{attrs:{"type":"sync","spin":_vm.loadingRefresh}}),_vm._v("Refresh ")],1)],1)],1),_c('a-table',{staticClass:"table-scroll",attrs:{"columns":_vm.columns,"data-source":_vm.datas,"loading":_vm.loading,"pagination":_vm.pagination,"bordered":""},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"Status",fn:function(text, record){return _c('span',{style:({
        cursor:
          record.statusAttendance !== 'done' && !_vm.isOnSEB
            ? 'not-allowed'
            : record.statusAttendance !== 'done' && _vm.isOnSEB
            ? 'pointer'
            : 'default',
      })},[(record.isNow)?[(record.statusAttendance === 'done')?_c('a',{staticClass:"btn btn-info mx-2",attrs:{"disabled":""}},[_vm._v("Closed")]):_c('a',{class:[
            'btn',
            'btn-outline-info',
            'mx-2',
            _vm.isOnSEB && record.type !== 'PH' ? 'disableBtn' : '',
          ],attrs:{"disabled":_vm.status.status_spp === 'unpaid' ||
            (_vm.isOnSEB && record.type !== 'PH')},on:{"click":function($event){$event.preventDefault();return (() => _vm.toExam(record.key, record.type)).apply(null, arguments)}}},[_vm._v(_vm._s(!_vm.isOnSEB && record.type !== 'PH' && record.type !== 'PPDB' ? 'Open SEB' : 'Take Exam')),(!_vm.isOnSEB && record.type !== 'PH' && record.type !== 'PPDB')?_c('img',{staticClass:"ml-2",style:({
              width: '25px',
              height: '25px',
              objectFit: 'contain',
            }),attrs:{"src":"/resources/images/seb.png","alt":"SEB"}}):_vm._e()])]:[(record.isUndone)?_c('p',[_c('a-icon',{attrs:{"type":"hourglass"}}),_vm._v(" "+_vm._s(_vm.findDayDifferenceUndone(record.momentDateStart))+" ")],1):_c('a',{staticClass:"btn btn-info mx-2",attrs:{"disabled":""}},[_vm._v("Closed")])]],2)}}])},[_c('a-icon',{attrs:{"slot":"filterIcon","type":"search"},slot:"filterIcon"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }